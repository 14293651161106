
export const CommonAccountConstant = {
    Table: {
        PRIORITY: "Priority",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        SERVICE_CODE: "Service Code",
        SOA: "SOA",
        ETD: "ETD",
        STATUS: "Status",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        OPS_LINE: "Ops Line",
        COMMON_AC_TYPE: "Common A/C Type",
        CHARGE_IND: "Charge Ind.",
        ACTIVE_IND: "Active Ind.",
        BILL_TO_COMPANY: "Bill-to Company",
        CHARGE_ON_COMPANY: "Charge-on Company",
        PERCENTAGE: "Percentage",
        CO_VSL_VOY: 'Co/Vsl/Voy',
    },
    Title: {
        CATEGORY: "Charge",
        TITLE: "Common Account",
        COMPANY_TITLE: "Common Account Company",
    },
}
export const commonAccountRequiredFieldList: string[] = [
    'commonAcType',
    'activeInd',
];
export const commonAccountCompanyRequiredFieldList: string[] = [
    'chargeOnCompany',
    'billToCompany',
    'cercentage',
];