import { CommonAccountCompanyEntity, EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY } from "domain/entity/CommonAccount/CommonAccountCompanyEntity";
import { CommonAccountEntity, EMPTY_COMMON_ACCOUNT_ENTITY, } from "domain/entity/CommonAccount/CommonAccountEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CommonAccountRepository } from "domain/repository/CommonAccount/CommonAccountRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import _ from "lodash";
import { CommonAccountModel } from "presentation/model/CommonAccount/CommonAccountModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface CommonAccountVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CommonAccountModel>> | ((value: SetStateAction<CommonAccountModel>) => void),
    ],
    commonAccountRepo:CommonAccountRepository,
    chargeTypeRepo: ChargeTypeRepository,
    consortiumRepo: ConsortiumRepository,
    opsLineRepo: OpsLineRepository,
    companyRepo: CompanyRepository,
    masterDataRepo: MasterDataRepository,
}

export const CommonAccountVM = ({dispatch, commonAccountRepo,chargeTypeRepo,consortiumRepo,opsLineRepo,companyRepo,masterDataRepo
}:CommonAccountVMProps) => {
    const [commonAccountDispatch] = dispatch;

    const loadDropdownOption = async() => {


        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOption:DropdownProps[] = [];
                let subChargeTypeDropdownOption:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel:opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )
        /*
        await consortiumRepo.getAllConsortiums().then(
            consortiums => {
                let consortiumCodeDropdownOptions = consortiums?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );
        */
        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }           
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SOA).then(
            soas => {
                const soaCodeDropdownOptions = soas?.map((soa)=>({
                    dropdownLabel: soa.code,
                    tagLabel: soa.code,
                    value: soa.code,
                })) ?? []

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        soaDropdownOptions: soaCodeDropdownOptions
                    }
                }))
            }           
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd)=>({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: chargeIndDropdownOptions
                    }
                }))
            }           
        );


        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                commonAccountDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

    }

    const searchAllCommonAccountList = async() => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_COMMON_ACCOUNT_ENTITY},
                currentEditRow: {...EMPTY_COMMON_ACCOUNT_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows:[],
                commonAccountList: [],
                currentCompanyEditRow: {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY},
                tempCompanyRows:[],
            }
        })
        await commonAccountRepo.searchAllCommonAccountList().then(data =>{
            if(data){
                commonAccountDispatch(prevState => {
                    return {
                        ...prevState,
                        commonAccountList: data,
                    }
                })
            }else{
                commonAccountDispatch(prevState => {
                    return {
                        ...prevState,
                        commonAccountList: [],
                    }
                })
            }
        })
    }

    const onRowDrag = (rows: CommonAccountEntity[]) => {
        commonAccountDispatch(prevState =>{ 
            const prevRows = prevState.commonAccountList;
            const toChangedRows = rows.map((e, index) => ({...e,tempPriority: prevRows[index].priority})).filter(e=>e.tempPriority!==e.priority);

            return ({
                ...prevState,
                commonAccountList: rows,
                updatedRows: toChangedRows,
        })})
    }

    const onApply = async (updatedRows: CommonAccountEntity[]) => {

        return await commonAccountRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows:CommonAccountEntity[]) => {

        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                // commonAccountList: [...allRows],
                selectedRows: rows,
            }
        })
    }

    const updateSelectedCompanyRows = async (updatedRows:CommonAccountCompanyEntity[]) => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                selectedCompanyRows: updatedRows,
                currentCompanyEditRow:updatedRows.length===1?updatedRows[0]:{...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY}
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if(fieldKey === 'chargeType'){
            commonAccountDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val===prevState.currentEditRow.chargeType?prevState.currentEditRow.subChargeType:''
                    },
                }
            })
        }
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onCompanyFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        commonAccountDispatch(prevState => {
            if(fieldKey==='percentage'){
                var posIntReg = /^\d*(\.\d{0,2})?$/;
                if (!posIntReg.test(val)) { 
                    val = prevState.currentCompanyEditRow[fieldKey];
                }
                // if (posIntReg.test(val) && (val > 99.99 || val < 0.01)) { 
                //     val = '';
                // }
            }
            return {
                ...prevState,
                currentCompanyEditRow: {
                    ...prevState.currentCompanyEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: CommonAccountEntity) => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                tempCompanyRows:entity.companyDetails??[],
                isShowEditPanel: true,
            }
        })
    }

    const onCompanyRowDoubleClick = async (entity: CommonAccountCompanyEntity) => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentCompanyEditRow: entity,
                isShowCompanyEditPanel: true,
            }
        })
    }

    const onCompanyDtlDeleteClick = async (entitys: CommonAccountCompanyEntity[]) => {
        commonAccountDispatch(prevState => {
            let dtls = prevState.tempCompanyRows.slice();
            const newDtls = dtls.filter(a => !entitys.some(b => {
                if (b.id === 0) {
                    return b.tempKey === a.tempKey;
                } else {
                    return b.id === a.id;
                }
            }));
            return {
                ...prevState,
                currentEditRow:{
                    ...prevState.currentEditRow,
                    companyDetails:newDtls,
                },
                tempCompanyRows: newDtls,
            }
        })
    }

    const onCompanyAddRowClick = async () => {
        let newEty = {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY};
        newEty = {
            ...newEty,
            tempKey:_.uniqueId()
        }
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentCompanyEditRow: newEty,
                isShowCompanyAddPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentCompanyEditRow: {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY},
                tempCompanyRows:[],
                currentEditRow: {...EMPTY_COMMON_ACCOUNT_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onCloseCompanyEidtPanel = async () => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentCompanyEditRow: {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY},
                isShowCompanyAddPanel: false,
                isShowCompanyEditPanel: false,
            }
        })
    }

    const onSave = async (currentRow: CommonAccountEntity) => {
        return commonAccountRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_COMMON_ACCOUNT_ENTITY},
                isShowEditPanel: true,
                isAdd: true,
            }
        })
    }

    const onCompanyTempSave = async (companyEditRow: CommonAccountCompanyEntity) => {
        commonAccountDispatch(prevState => {
            let newDtls = prevState.tempCompanyRows.slice();
            let existingDtlIndex = newDtls.findIndex(dtl => {
                if(dtl.id !== 0){
                    return dtl.id === companyEditRow.id
                }else{
                    return dtl.tempKey === companyEditRow.tempKey
                }

            });
            if(existingDtlIndex !== -1){
                Object.assign(newDtls[existingDtlIndex], companyEditRow);
            } else {
                newDtls.push(companyEditRow);
            }
            return {
                ...prevState,
                tempCompanyRows: newDtls,
                currentEditRow:{
                    ...prevState.currentEditRow,
                    companyDetails:newDtls,
                },
                currentCompanyEditRow: {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY},
                isShowCompanyEditPanel: false,
                isShowCompanyAddPanel: false,
            }
        })
    }
    const onResetClick = async (isAdd:boolean) => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_COMMON_ACCOUNT_ENTITY}:prevState.currentRow,
                tempCompanyRows:isAdd?[]:prevState.currentRow.companyDetails,
                isShowCompanyEditPanel: false,
                isShowCompanyAddPanel: false,
                currentCompanyEditRow: {...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY},
            }
        })
    }
    const onEditClick = async () => {
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        commonAccountDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        let coVal : string = "";
        let vslVal : string = "";
        let voyVal : string = "";
        if(inputData?.co){
            coVal = inputData?.co.toUpperCase().replace(/\s+/g, '');
        }
        if(inputData?.vsl){
            vslVal = inputData?.vsl.toUpperCase().replace(/\s+/g, '');
        }
        if(inputData?.voy){
            voyVal = inputData?.voy.toUpperCase();
        }
        
        commonAccountDispatch(prevState => ({
            ...prevState,
            currentEditRow: {
                ...prevState.currentEditRow,
                [fieldName.co]: coVal,
                [fieldName.vsl]: vslVal,
                [fieldName.voy]: voyVal,
            }
        }))
    }

    return {
        onCoVslVoyChange: onCoVslVoyChange,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onCompanyDtlDeleteClick: onCompanyDtlDeleteClick,
        onEditClick: onEditClick,
        onCompanyTempSave: onCompanyTempSave,
        onCloseCompanyEidtPanel: onCloseCompanyEidtPanel,
        onCompanyAddRowClick: onCompanyAddRowClick,
        onCompanyRowDoubleClick: onCompanyRowDoubleClick,
        onCompanyFieldChange: onCompanyFieldChange,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        updateSelectedCompanyRows: updateSelectedCompanyRows,
        onApply: onApply,
        onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllCommonAccountList: searchAllCommonAccountList,
    }
}