export interface CommonAccountCompanyEntity{
    id?: number | null,
    tempKey?: string | null,
    chargeShareId?: number | null,
    chargeOnCompany?: string | null,
    billToCompany?: string | null,
    percentage: number | null,

    [key: string]: string | boolean | null | Object | undefined
}

export const EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY : CommonAccountCompanyEntity = {

    id: 0,
    tempKey: null,
    percentage: null,
    chargeShareId: 0,
    billToCompany: '',
    chargeOnCompany: '',
}