import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CommonAccountRepoImpl } from "domain/repository/CommonAccount/CommonAccountRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { useCommonAccountTracked } from "presentation/store/CommonAccount/CommonAccountProvider";
import { CommonAccountVM } from "presentation/viewModel/CommonAccount/CommonAccountVM";
import { useMemo } from "react";

export const useCommonAccountVM = () => {
    const [, setCommonAccountState] = useCommonAccountTracked();
    const commonAccountVM = useMemo(() =>
        CommonAccountVM({
            dispatch: [setCommonAccountState],
            commonAccountRepo: CommonAccountRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setCommonAccountState])

    return commonAccountVM
}