import { commonAccountCompanyRequiredFieldList, CommonAccountConstant } from "presentation/constant/CommonAccount/CommonAccountConstant";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCommonAccountTracked } from "presentation/store/CommonAccount/CommonAccountProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CommonAccountCompanyEditPanel = () => {
    const COMMON_ACCOUNT_CONSTANT= CommonAccountConstant.Table;
    const [commonAccountState] = useCommonAccountTracked();
    const commonAccountVM = useCommonAccountVM();
    const {isShowCompanyAddPanel,isShowCompanyEditPanel,currentCompanyEditRow} = commonAccountState;
    const messageBarVM = useMessageBarVM();
    

    const handleSave = useCallback(async () => {
        if(!currentCompanyEditRow.billToCompany){
            messageBarVM.showError("Bill To Company is mandatory.");
            return;
        }
        if(!currentCompanyEditRow.chargeOnCompany){
            messageBarVM.showError("Charge On Company is mandatory.");
            return;
        }
        if(currentCompanyEditRow.percentage === null){
            messageBarVM.showError("Percentage is mandatory.");
            return;
        }
        if(currentCompanyEditRow.percentage < 0.01 || currentCompanyEditRow.percentage > 99.99){
            messageBarVM.showError("Input Percentage Value should be range from 0.01 to 99.99.");
            return;
        }
        commonAccountVM.onCompanyTempSave(currentCompanyEditRow);
    }, [commonAccountVM, currentCompanyEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        commonAccountVM.onCloseCompanyEidtPanel();
    }, [commonAccountVM]);

    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        commonAccountVM.onCompanyFieldChange(fieldName, fieldValue);
    }, [commonAccountVM]);

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={currentCompanyEditRow?.billToCompany}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                options={commonAccountState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={commonAccountCompanyRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onCompanyFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [currentCompanyEditRow?.billToCompany, COMMON_ACCOUNT_CONSTANT.BILL_TO_COMPANY, commonAccountState.dynamicOptions.companyCodeDropdownOptions, commonAccountVM])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={currentCompanyEditRow?.chargeOnCompany}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                maxLength={60}
                options={commonAccountState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={commonAccountCompanyRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onCompanyFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [currentCompanyEditRow?.chargeOnCompany, COMMON_ACCOUNT_CONSTANT.CHARGE_ON_COMPANY, commonAccountState.dynamicOptions.companyCodeDropdownOptions, commonAccountVM])

        const memoPercentage = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"220px", marginBottom:"24px"}}>
                <NumberInputComponent
                    label={COMMON_ACCOUNT_CONSTANT.PERCENTAGE}
                    maxLength={60}
                    fieldName={"percentage"}
                    value={currentCompanyEditRow.percentage ?? ''}
                    errorMessage={''}
                    disabled={false}
                    decimalPlaces={2}
                    onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue)}
                />
            </div>
            , [COMMON_ACCOUNT_CONSTANT.PERCENTAGE, currentCompanyEditRow.percentage, onNumberFieldChange])

            
    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                        <Sidebarheader style={{ width: '100%' }}>
                            <SidebarTitle>
                                {isShowCompanyAddPanel&&"New Common Account Company"}
                                {isShowCompanyEditPanel&&"Edit Common Account Company"}
                            </SidebarTitle>
                            <SidebarActionCross>
                                <>
                                    <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                                </>
                            </SidebarActionCross>

                        </Sidebarheader>

                    </StyledSidebar>
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        <CriteriaItemContainer>
                            {memoBillToCompany}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoChargeOnCompany}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoPercentage}
                        </CriteriaItemContainer>


                    </div>
                </div>
            </div>

        </div>
    </>
}

export default memo(CommonAccountCompanyEditPanel);
