import { EMPTY_COMMON_ACCOUNT_MODEL } from "presentation/model/CommonAccount/CommonAccountModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CommonAccountProvider,
    useTracked: useCommonAccountTracked
} = createContainer(() => useState(EMPTY_COMMON_ACCOUNT_MODEL), {concurrentMode: true});
export { CommonAccountProvider, useCommonAccountTracked };

