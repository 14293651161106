import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CommonAccountProvider } from "presentation/store/CommonAccount/CommonAccountProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import CommonAccountMaintenance from "presentation/view/section/CommonAccount/CommonAccountMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CommonAccountContMain = () => {
    return <ANAInfoWrapper permission={Permission.COMMON_ACCOUNT_MAINTENANCE}>
        <MessageBarWrapper>
            <CommonAccountProvider>
                <GridStyles/>
                <CommonAccountMaintenance/>
            </CommonAccountProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CommonAccountContMain;