import { CommonAccountCompanyEntity } from "./CommonAccountCompanyEntity"

export interface CommonAccountEntity{
    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    serviceCode?: string | null,
    serviceCodeList?: string[] | null,
    soa?: string | null,
    etd?: Date | null,
    cntrStatus?: string | null,
    cntrStatusList?: string[] | null,
    chargeType?: string | null,
    subChargeType?: string | null,
    owner?: string | null,
    ownerList?: string[] | null,
    commonAcType?: string | null,
    chargeInd?: string | null,
    activeInd?: string | null,
    shareType?: string | null,
    companyDetails: CommonAccountCompanyEntity[],

    [key: string]: string | boolean | null | Object | undefined
}

export const EMPTY_COMMON_ACCOUNT_ENTITY : CommonAccountEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    consortiumCode: '',
    vesselCode: '',
    voyageCode: '',
    serviceCode: '',
    serviceCodeList: [],
    soa: '',
    etd: null,
    cntrStatus: '',
    cntrStatusList: [],
    chargeType: '',
    subChargeType: '',
    owner: '',
    ownerList: [],
    commonAcType: '',
    chargeInd: '',
    activeInd: 'Y',
    shareType: "CommonAccountEntity",
    companyDetails: [],
}