import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useCommonAccountTracked } from "presentation/store/CommonAccount/CommonAccountProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CommonAccountEditPanel from "./RightPanel/CommonAccountEditPanel";
import CommonAccountCompanyTablePanel from "./Table/CommonAccountCompanyTablePanel";
import CommonAccountTablePanel from "./Table/CommonAccountTablePanel";


const CommonAccountMaintenance: React.FC = () => {
    const [commonAccountState] = useCommonAccountTracked();
    const [isLoading, setIsLoading] = useState(true);
    const commonAccountVM = useCommonAccountVM();
    const { isShowEditPanel } = commonAccountState;


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    commonAccountVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            commonAccountVM.searchAllCommonAccountList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [commonAccountVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper`}>

            {!(isShowEditPanel) && <>
                <CommonAccountTablePanel /></>}
            {(isShowEditPanel) && <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"50%"}
                rightSectionWidth={"50%"}
                leftChildren={<CommonAccountEditPanel commonAccountState={commonAccountState} />}
                rightChildren={<CommonAccountCompanyTablePanel commonAccountState={commonAccountState} />}
            />}
        </div>
    </>
}

export default memo(CommonAccountMaintenance);