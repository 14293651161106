import { CommonAccountCompanyEntity, EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY } from "domain/entity/CommonAccount/CommonAccountCompanyEntity";
import { CommonAccountEntity, EMPTY_COMMON_ACCOUNT_ENTITY } from "domain/entity/CommonAccount/CommonAccountEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CommonAccountDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    serviceCodeDropdownOptions: DropdownProps[],
    soaDropdownOptions: DropdownProps[],
    // consortiumCodeDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    opsLineDropdownOptions: DropdownProps[],
}
export interface CommonAccountViewChangeState extends BaseViewChangeSate {
}

export interface CommonAccountModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    isShowCompanyEditPanel:boolean,
    isShowCompanyAddPanel:boolean,
    dynamicOptions: CommonAccountDropdownOptions,
    commonAccountList: CommonAccountEntity[],
    selectedRows: CommonAccountEntity[],
    updatedRows: CommonAccountEntity[],
    currentRow: CommonAccountEntity,
    currentEditRow: CommonAccountEntity,
    currentCompanyEditRow: CommonAccountCompanyEntity,
    selectedCompanyRows: CommonAccountCompanyEntity[],
    tempCompanyRows: CommonAccountCompanyEntity[],
    
}

export const EMPTY_COMMON_ACCOUNT_MODEL: CommonAccountModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    isShowCompanyEditPanel: false,
    isShowCompanyAddPanel: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        serviceCodeDropdownOptions: [],
        soaDropdownOptions: [],
        // consortiumCodeDropdownOptions: [],
        companyCodeDropdownOptions: [],
        chargeIndDropdownOptions: [],
        opsLineDropdownOptions: [],
    },
    commonAccountList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_COMMON_ACCOUNT_ENTITY },
    currentEditRow: { ...EMPTY_COMMON_ACCOUNT_ENTITY },
    currentCompanyEditRow: { ...EMPTY_COMMON_ACCOUNT_COMPANY_ENTITY },
    selectedCompanyRows: [],
    tempCompanyRows: [],
}