import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CommonAccountEntity } from "domain/entity/CommonAccount/CommonAccountEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { CommonAccountRepository } from "./CommonAccountRepo";

export const CommonAccountRepoImpl = (): CommonAccountRepository => {
    
    const searchUrl = "/v1/searchAllCommonAccountList"

    const searchAllCommonAccountList = async() : Promise<CommonAccountEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: CommonAccountEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/commonAccountApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: CommonAccountEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/commonAccountSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }


    return {
        onSave: onSave,
        onApply: onApply,
        searchAllCommonAccountList : searchAllCommonAccountList,
    }
}
